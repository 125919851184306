
const shopChoicesOpeners = [].slice.call(document.querySelectorAll(".js-shop-choices-opener"));
if (shopChoicesOpeners) {
    shopChoicesOpeners.forEach((shopChoicesOpener) => {
        shopChoicesOpener.addEventListener('click', (e) => {
            e.stopImmediatePropagation();
            e.preventDefault();
            
            if (!e.target.closest('.shop-choices').classList.contains('shop-choices--active')) {
                e.target.closest('.shop-choices').classList.add('shop-choices--active')
            } else {
               e.target.closest('.shop-choices').classList.remove('shop-choices--active')
            }
        })
    });
}

const iframeOpeners = [].slice.call(document.querySelectorAll(".js-open-wavy-iframe"));
if (iframeOpeners) {
    iframeOpeners.forEach((iframeOpener) => {
        iframeOpener.addEventListener('click', (e) => {
            e.stopImmediatePropagation();
		    e.preventDefault();
            if (e.target.closest('.shop-choices')) {
                e.target.closest('.shop-choices').classList.remove('shop-choices--active');
            }

            e.target.closest('.wp-block-wavy-opener').classList.add('wp-block-wavy-opener--loading');

            document.getElementById('wavy-iframe-wrapper').innerHTML = '';
            jQuery('body > iframe').remove();

            const url = e.target.getAttribute('data-url');
            const s = document.createElement('script');
            s.setAttribute('src', url);
            document.getElementById('wavy-iframe-wrapper').appendChild(s);

            setTimeout(() => {
                document.querySelector('.wavy-open-button').click();
                e.target.closest('.wp-block-wavy-opener').classList.remove('wp-block-wavy-opener--loading');
            }, 2500);
        })
    });
}

